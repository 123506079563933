<template>
    <div v-loading="loadingData">
        
        <el-form  ref="form" :model="form"  class="aticler_m" size="small">
            <div>
                <h4 style="color: red" class="error_cls">{{ message }}</h4>
                <el-form-item>

                    <template v-if="selectedIndex  == 'plus'">
                        <div class="prepare_li_radio on_li">
                            <el-radio label="minus" v-model="form.index"   size="medium">{{ $t('message.nurse_wrong_choice') }}</el-radio>
                        </div>
                    </template>

                    <template v-if="selectedIndex == 'minus'">
                        <div class="prepare_li_radio on_li">
                            <el-radio label="plus" v-model="form.index"   size="medium">{{ $t('message.done_treatment') }}</el-radio>
                        </div>
                        <div class="prepare_li_radio on_li">
                            <el-radio label="reject" v-model="form.index"   size="medium">{{ $t('message.patient_reject') }}</el-radio>
                        </div>
                        <div class="prepare_li_radio off_li">
                            <el-radio label="defect"  v-model="form.index" size="medium">{{ $t('message.drug_bad_reaction') }}</el-radio>
                        </div>
                    </template>

                    <template v-if="selectedIndex == 'continue'">
                    </template>

                    <template v-if="selectedIndex == 'reject'">
                    </template>

                    <template v-if="selectedIndex == 'defect'">
                        <div class="prepare_li_radio on_li">
                            <el-radio label="continue" v-model="form.index"   size="medium">{{ $t('message.drug_good_reaction') }}</el-radio>
                        </div>
                        <div class="prepare_li_radio on_li">
                            <el-radio label="minus" v-model="form.index"   size="medium">{{ $t('message.nurse_wrong_choice') }}</el-radio>
                        </div>
                    </template>

                     <!-- <div class="prepare_li_radio on_li">
                        <el-radio label="plus" v-model="form.index"   size="medium">Сделано ( препарат успешно введен )</el-radio>
                    </div>
                    <div class="prepare_li_radio on_li">
                        <el-radio label="continue" v-model="form.index"   size="medium">Далее  ( Реакция не от этого препарата продолжайте процедуру )</el-radio>
                    </div>
                    <div class="prepare_li_radio on_li">
                        <el-radio label="reject" v-model="form.index"   size="medium">Пациент отказал/а</el-radio>
                    </div>
                    <div class="prepare_li_radio on_li">
                        <el-radio label="minus" v-model="form.index"   size="medium">Медсестра ошибся в выборе клеток</el-radio>
                    </div>
                    <div class="prepare_li_radio off_li">
                        <el-radio label="defect"  v-model="form.index" size="medium">Дефекты  ( После введения препарата, была реакция )</el-radio>
                    </div> -->

                    <div>
                        <el-input
                            class="mt-2"
                            type="textarea"
                            :rows="2"
                            :placeholder="$t('message.comment')"
                            v-model="form.comment"  
                        ></el-input>
                    </div>
                </el-form-item>
            </div>
        </el-form>

        <el-row class="row_but">
             <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save') }}</el-button>
            <el-button @click="close()">{{ $t('message.close') }}</el-button>
        </el-row>
    </div>
</template>

<script>
    import {mapGetters,mapActions} from 'vuex';
    import form from '@/utils/mixins/form';

    
    import drawerChild from '@/utils/mixins/drawer-child';
    export default {
       mixins:[form, drawerChild],
        props:['selected'],
        components:{},
        data(){
            return {
                selectedIndex: '',
                message: '',
                loadingData: false,
                dialogTableVisible2: false,
            }
        },
         watch: {
            reloadModel: {
                handler: function () {
                    if (this.reloadModel){
                        this.loadModel()
                    }
                },
                deep: true
            },
        },
        created(){},
        computed: {
            ...mapGetters({
                rules: 'dailyTreatmentIndeces/rules',
                columns: 'dailyTreatmentIndeces/columns',
                model: 'dailyTreatmentIndeces/model',
                errormessage: 'dailyTreatmentIndeces/errormessage',
            })
        },
        methods:{
            ...mapActions({
                save: 'dailyTreatmentIndeces/update',
                editModel: 'dailyTreatmentIndeces/show',
                empty: 'dailyTreatmentIndeces/empty',
                updateList: 'dailyTreatmentIndeces/index',
            }),
            afterOpened(){
                if (this.selected && !this.loadingData) {
                    this.loadingData = true;
                    this.editModel(this.selected.id)
                        .then(async (res) => {
                            this.loadingData = false;
                            this.form = JSON.parse( JSON.stringify(this.model) );
                            this.selectedIndex = this.form.index
                        })
                        .catch(err => {
                            this.loadingData = false;
                            this.$alert(err)
                        });                    
                }
            },
            fetchData() {
                const query = { ...this.filter };
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.updateList(query).then(res => {
                        this.loadingData = false
                    }).catch(err => {
                        this.loadingData = false
                    });
                }
            },
            close(){
                this.$emit('c-close');
                this.message = '';
            },
            clean(){
                this.message = '';
            },
            afterClosed(){
              this.empty()
            },
            loadModel(){
              this.form = JSON.parse( JSON.stringify( this.model ));
            },
            submit(close = true){
                this.form.daily_treatment_id = this.model.dailyTreatment.id;
                this.$refs['form'].validate((valid) => {
                    if (valid){
                        this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.parent().listChanged()
                                 if (close == true) {
                                    this.close();
                                }
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                this.message = this.errormessage + '!';
                                // this.$alert(err);
                            });
                    }
                });
            },
        }
         
    }
</script>
<style>
    .error_cls{
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
    }
</style>