<template>
  <div>
    <div class="itme-defects" v-for="date in defect" :key="date.id + '-datess'">
      <el-row :gutter="30">
        <el-col :span="6">
              <div class="nome__medicine" v-for="i in date.dailyTreatment.products" :key="i.id + 'products'">{{ i.name }}<br></div>
              <div class="nome__medicine" v-for="v in date.dailyTreatment.dailyTreatmentNames" :key="v.id + '-dtName'">{{ v.name }}<br></div>
        </el-col>
        <el-col :span="12">
          <div class="nome__medicine_status">{{ date.comment }}</div>
        </el-col>
        <el-col :span="4">
          <div class="nome__medicine_data">{{ date.day }}</div>
        </el-col>
        <!-- <el-col :span="2">
          <el-button
           @click="drawer.update.status = true"
            type="success"
            size="small"
            icon="el-icon-refresh"
            plain
            round
          >Замена</el-button>
        </el-col> -->
      </el-row>
    </div>
    <!-- end itme-defects -->
        <el-drawer 
            size="60%" 
            :visible.sync="drawer.update.status"
            :ref="drawer.update.name"
            @opened="drawerOpened(drawer.update.component)"
            @closed="drawerClosed(drawer.update.component)">
                <crm-update :selected="selectedModel" :ref="drawer.update.component" :drawer-name="drawer.update.name"></crm-update>
        </el-drawer>
  </div>
</template>
<script>
import CrmUpdate from "./addDefectsModal";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
    export default {
        mixins:[list ,drawer],
        name: "dailyTreatment",
        components:{
            CrmUpdate,
        },
        data(){
            return {
                loadingData: false,
                selectedModel: {},
                drawer: {
                    update: {
                        name: "update",
                        status: false,
                        component: 'componentDrawerUpdate'
                    }
                }
            }
        },
        computed:{
            getId(){
                return this.$route.params.id;
              },
            ...mapGetters({
                defect: 'dailyTreatments/defect',
                columns: "dailyTreatments/columns",
                pagination: "dailyTreatments/pagination",
                filter: "dailyTreatments/filter",
                sort: "dailyTreatments/sort",
                dailyTreatment: 'dailyTreatments/model'
            }),
            actions: function() {
              return ['edit', 'delete'];
            }
        },
        methods: {
            ...mapActions({
                updateList: 'dailyTreatments/defect',
                updateSort: "dailyTreatments/updateSort",
                updateFilter: "dailyTreatments/updateFilter",
                updateColumn: "dailyTreatments/updateColumn",
                updatePagination: "dailyTreatments/updatePagination",
                editModel: 'dailyTreatments/show',
                empty: 'dailyTreatments/empty',
                delete: 'dailyTreatments/destroy',
                refreshData: 'dailyTreatments/refreshData',
            }),
            fetchData(){

            },
            fetchDataAny() {
                this.loadingData = true;
                this.updateList()
                    .then(res => {
                        this.loadingData = false;            
                    }).catch(err => {
                        this.loadingData = false;
                        this.$alert(err)
                    });
            },
            refresh() {
                this.refreshData()
                    .then(res => {
                        this.filterForm = JSON.parse( JSON.stringify( this.filter ));
                    })
                    .catch(err => {

                    })
            },
            destroy(model){
                this.delete(model.id)
                    .then(res => {
                        this.$alert(res);
                        this.fetchData()
                    })
                    .catch(err => {
                       console.log(err)
                    })
            },
            emptyModel(){
                this.empty()
            },
        }
};
</script>
